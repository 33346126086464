import {
    AdminRouterKey
} from "../../utils/user";
import router from "@/router"


const state = {
    adminRouter: [], //从api借口拿到的  后台管理员的路由['login','dashboad','statics']
    topMenuTag: "", // 左侧边栏的菜单 路由


    Routerlist: [],
    siderRouter: [],
}

const mutations = {

    setRouterlist: (state, routers) => {
        state.Routerlist = routers

    },
    setsiderRouter: (state, routers) => {
        state.siderRouter = routers
    },


    SET_ADMIN_ROUTER: (state, routers) => {
        console.log("--------store/module/router/mutations/SET_ADMIN_ROUTER----------------------")
        console.log(routers)
        state.adminRouter = routers
    },
    SET_TOP_MENU_TAG: (state, menuTag) => {
        console.log("--------store/module/router/mutations/SET_TOP_MENU_TAG----------------------")
        state.topMenuTag = menuTag
    },
}

const actions = {
    SET_ADMIN_ROUTER({
        commit
    }, adminRouters) {
        console.log("--------store/module/router/action/SET_ADMIN_ROUTER----------------------")
        console.log(adminRouters, '路由参数')
        // console.log(router.options)
        // console.log(vuerouter, '本地路由')
        // let routerPathList = []
        // for (const router of adminRouters) {
        //     routerPathList.push(router.url)
        // }
        // console.log(routerPathList, '最终放入本地存储');
        // localStorage.setItem(AdminRouterKey, JSON.stringify(adminRouters))
        // localStorage.setItem('VueRouters', JSON vuerouter)
        // console.log(routerPathList)
        // commit("SET_ADMIN_ROUTER", adminRouters)
    },

    set_Routerlist({
        commit
    }, routerlist) {
        localStorage.setItem('Routerlist', JSON.stringify(routerlist))
    },

    set_siderRouter({
        commit
    }, sidelist) {
        localStorage.setItem('siderRouter', JSON.stringify(sidelist))
    },

    SET_TOP_MENU_TAG({
        commit
    }, menuTag) {
        console.log("--------store/module/router/action/SET_TOP_MENU_TAG----------------------")
        localStorage.setItem("topMenuTag", menuTag)
        commit("SET_ADMIN_ROUTER", menuTag)
    }
}
const getters = {
    GET_ADMIN_ROUTER: state => {
        let storeRouter = state.adminRouter
        let storageRouter = localStorage.getItem(AdminRouterKey)
        if (storeRouter.length === 0) {
            return storageRouter
        }
        return storeRouter
    },

    GET_TOP_MENU_TAG: state => {
        let tag = state.topMenuTag
        let storageTag = localStorage.getItem("topMenuTag")
        if (tag === null || tag.length === "") {
            return storageTag
        }
        return tag
    },

    get_Routerlist: state => {
        let routerlist = state.Routerlist;
        let storage = JSON.parse(localStorage.getItem('Routerlist'))
        if (routerlist == null || routerlist == undefined || routerlist.length == 0) {
            return storage;
        }
        return routerlist;
    },
    get_siderRouter: state => {
        let routerlist = state.siderRouter;
        let storage = JSON.parse(localStorage.getItem('siderRouter'))
        if (routerlist == null || routerlist == undefined || routerlist.length == 0) {
            return storage;
        }
        return routerlist;
    }
}


export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations,
}