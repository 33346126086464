const state = {
    list: ['a', 'b'],
    count: 0
}

const mutations = {
    mutations_push(state, text) {
        state.list.push(text)
    },
    mutations_add(state) {
        state.count++
    },
    mutations_reduce(state) {
        state.count--
    }
}

const actions = {
    actions_push: ({ commit }, text) => {
        commit('mutations_push', text)
    },
    actions_add: ({ commit }) => {
        commit('mutations_add')
    },
    actions_reduce: ({ commit }) => {
        commit('mutations_reduce')
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}