import Vue from 'vue';
import App from './App.vue';
import router, {
    constantRouterMap
} from './router';
import store from './store/index';
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import {
    messages
} from './common/i18n';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
// import './assets/css/theme-green/index.css'; // 浅绿色主题
import '../theme/index.css'
import './assets/css/icon.css';
import './common/directives';
import 'babel-polyfill';
import vcolorpicker from 'vue-color'
import echarts from 'echarts'
import $ from 'jquery'
import { getUserInfo } from "./utils/user";

import VueClipboard from 'vue-clipboard2'


Vue.prototype.$ = $;
Vue.prototype.$store = store;



// import Blob from "./utils/blob";
// import Export2Excel from "./utils/Export2Excel";
// if (process.env.development) {    // 判断是否为mock模式
//     require('./mock/index')
// }
Vue.prototype.echarts = echarts
Vue.use(vcolorpicker)
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(ElementUI, {
    size: 'small'
});
const i18n = new VueI18n({
    locale: 'zh',
    messages
});


VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)



function matching(topath, roulist) {
    let istopath = false;
    roulist.forEach(item => {
        item.subs.forEach(val => {
            if (val.index == topath) {
                istopath = true;
            }
        })
    })
    return istopath;
}



//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {

    let adminrouter = JSON.parse(localStorage.getItem('siderRouter')) || [];

    // 路由匹配，匹配不上进入403页面
    let isNext = false;
    adminrouter.forEach(item => {
        let index = item.subs.findIndex(val => val.index == to.path);
        // console.log(index);
        if (index > -1) {
            isNext = true;
        }
    })

    console.log('路由跳转', to, adminrouter, isNext)

    const token = localStorage.getItem('token');
    let admin = getUserInfo()

    if (to.path == '/login') {
        // 首次进入支持直接跳登录页
        next();
    } else if (!token) {
        // 如果没有token，则跳转登录页
        next('/login');
    } else if (admin.is_super_admin !== null && admin.is_super_admin === 1) {
        // 如果是超级管理员,直接进入，不做拦截
        next();
    } else if (isNext) {
        // 匹配到路由，则有权限，直接进入页面
        next();
    } /*else if (!isNext) {
        // 路由匹配失败，则直接进入403页面
        if (to.path == '/403') {
            next();
        } else {
            next('/403');
        }
    } */else {
        // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
        if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
            Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
                confirmButtonText: '确定'
            });
        } else {
            next();
        }
    }
});

var vm = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
