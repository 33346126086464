import store from "../store"
export const AdminRouterKey ="admin_router"

export function getUserInfo(){
    let adminInfo =localStorage.getItem('user');
    let admin ={}
    if (adminInfo!=null&& adminInfo.length >0){
        admin =JSON.parse(adminInfo)
    }
    return admin
}



export function getAdminRouter(){
    let adminRouter =store.getters.adminRouter.adminRouter
    let router = {}
    if (adminRouter != null && adminRouter.length > 0) {
        console.log(adminRouter)
        router = JSON.parse(adminRouter)
    }else {
        return adminRouter
    }
    return router


}
