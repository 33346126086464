import Vue from 'vue';
import Router from 'vue-router';
import startQiankun from 'tmvc-base' // 注入乾坤基座配置

// 调用qiankun启动服务
startQiankun()

Vue.use(Router);
// 修复重复点击菜单报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export const constantRouterMap = [{
    path: '/',
    redirect: '/survey-Dashboard'
},
{
    path: '/概况',
    component: () => import( /* webpackChunkName: "home" */ '../components/Home.vue'),
    meta: {
        title: '自述文件'
    },
    children: [{
        path: '/survey-Dashboard',
        component: () => import( /* webpackChunkName: "dashboard" */ '../page/survey/Dashboard.vue'),
        meta: {
            title: '运营概况'
        }
    },
    {
        path: '/survey-Dashboard-Distributor',
        component: () => import( /* webpackChunkName: "dashboard" */ '../page/survey/DashboardOfDistributor.vue'),
        meta: {
            title: '运营概况'
        }
    },
    {
        path: '/survey-BigScreen',
        component: () => import( /* webpackChunkName: "dashboard" */ '../page/survey/BigScreen.vue'),
        meta: {
            title: '数据大屏'
        }
    },
    {
        path: '/shop-Banner',
        component: () => import( /* webpackChunkName: "icon" */ '../page/shop/Banner.vue'),
        meta: {
            title: '幻灯片'
        }
    },
    {
        path: '/shop-nav',
        component: () => import( /* webpackChunkName: "table" */ '../page/shop/Nav.vue'),
        meta: {
            title: '导航图标'
        }
    },
    {
        path: '/shop-ad',
        component: () => import( /* webpackChunkName: "tabs" */ '../page/shop/Ad.vue'),
        meta: {
            title: '广告'
        }
    },
    {
        path: '/shop-HotWordList',
        component: () => import( /* webpackChunkName: "form" */ '../page/shop/HotWordList.vue'),
        meta: {
            title: '标签管理'
        }
    },
    {
        path: '/shop-painmanagement',
        component: () => import('@/page/shop/painmanagement.vue'),
        meta: {
            title: '页面配置'
        }
    },
    {
        path: '/shop-Locationss',
        component: () => import('@/page/shop/Locationss.vue'),
        meta: {
            title: '位置管理'
        }
    },
    {
        path: '/shop-HomeEditor',
        component: () => import('@/page/shop/HomeEditor.vue'),
        meta: {
            title: '首页编辑器'
        }
    },
    {
        path: '/set-store-info',
        component: () => import('@/page/set/StoreInfo.vue'),
        meta: {
            title: '商城信息'
        }
    },
    {
        path: '/set-AppColor',
        component: () => import('@/page/set/AppColor.vue'),
        meta: {
            title: 'app配色'
        }
    },
    {
        path: '/set-trade',
        component: () => import('@/page/set/Trade.vue'),
        meta: {
            title: '交易设置'
        }
    },
    {
        path: '/set-Domain',
        component: () => import('@/page/set/Domain.vue'),
        meta: {
            title: '域名配置'
        }
    },
    {
        path: '/set-Poster',
        component: () => import('@/page/set/Poster.vue'),
        meta: {
            title: '海报设置'
        }
    },
    {
        path: '/set-CommentSet',
        component: () => import('@/page/set/CommentSet.vue'),
        meta: {
            title: '评论设置'
        }
    },
    {
        path: '/set-notes',
        component: () => import('@/page/set/notes.vue'),
        meta: {
            title: '助通短信'
        }
    },
    {
        path: '/set-versionings',
        component: () => import('@/page/set/Versionings.vue'),
        meta: {
            title: '版本管理'
        }
    },
    {
        path: '/set-unions',
        component: () => import('@/page/set/SystemUnion.vue'),
        meta: {
            title: 'Union管理'
        }
    },
    {
        path: '/set-Pageconfigure',
        component: () => import('@/page/set/Pageconfigure.vue'),
        meta: {
            title: '系统配置'
        }
    },
    {
        path: '/set-Weixin',
        component: () => import('@/page/set/Weixin.vue'),
        meta: {
            title: '微信配置'
        }
    },
    {
        path: '/set-BuryingPoint',
        component: () => import('@/page/set/BuryingPoint.vue'),
        meta: {
            title: '埋点配置'
        }
    },
    {
        path: '/set-SlidList',
        component: () => import('@/page/set/SlidList.vue'),
        meta: {
            title: '菜单配置'
        }
    },
    {
        path: '/set-RoleList',
        component: () => import('@/page/set/Rolelist.vue'),
        meta: {
            title: '角色配置'
        }
    },
    {
        path: '/set-Urllist',
        component: () => import('@/page/set/Urllist.vue'),
        meta: {
            title: '链接配置'
        }
    },

    {
        path: '/set-Agreement',
        component: () => import('@/page/set/Agreement.vue'),
        meta: {
            title: '协议管理'
        }
    },

    {
        path: '/set-Distribution',
        component: () => import('@/page/set/Distribution.vue'),
        meta: {
            title: '配送设置'
        }
    },
    {
        path: '/manags-student',
        component: () => import('@/page/manags/student.vue'),
        meta: {
            title: '直播管理'
        }
    },
    {
        path: '/manags-Roomconfig',
        component: () => import('@/page/manags/Roomconfig.vue'),
        meta: {
            title: '直播配置'
        }
    },
    {
        path: '/manags-livegod',
        component: () => import('@/page/manags/livegod.vue'),
        meta: {
            title: '直播商品'
        }
    },
    {
        path: '/sale-CodeList',
        component: () => import('@/page/sale/CodeList.vue'),
        meta: {
            title: '邀请码管理'
        }
    },
    {
        path: '/sale-Plugintable',
        component: () => import('@/page/sale/Plugintable.vue'),
        meta: {
            title: '插件表管理'
        }
    },
    {
        path: '/sale-Lnvitation',
        component: () => import('@/page/sale/Lnvitation.vue'),
        meta: {
            title: '邀请码订单'
        }
    },
    {
        path: '/sale-Plugingoods',
        component: () => import('@/page/sale/Plugingoods.vue'),
        meta: {
            title: '邀请码商品'
        }
    },
    {
        path: '/sale-Distri',
        component: () => import('@/page/sale/Distri.vue'),
        meta: {
            title: '物流管理'
        }
    },
    {
        path: '/Distribution',
        component: () => import('@/page/set/Distribution.vue'),
        meta: {
            title: '运费模板'
        }
    },
    {
        path: '/custom-list',
        component: () => import('@/page/custom/List.vue'),
        meta: {
            title: '客户管理'
        }
    },
    {
        path: '/custom-list-partner',
        component: () => import('@/page/custom/ListOfPartner.vue'),
        meta: {
            title: '客户管理'
        }
    }, {
        path: '/custom-Userauthen',
        component: () => import('@/page/custom/Userauthen.vue'),
        meta: {
            title: '客户认证'
        }
    },
    {
        path: '/custom-RankOrder',
        component: () => import('@/page/custom/RankOrder.vue'),
        meta: {
            title: '等级订单'
        }
    },
    {
        path: '/custom-RankGoodsOfDistributor',
        component: () => import('@/page/custom/RankGoodsOfDistributor'),
        meta: {
            title: '升级卡券'
        }
    },
    {
        path: '/custom-RankGoods',
        component: () => import('@/page/custom/RankGoods.vue'),
        meta: {
            title: '升级配置'
        }
    },
    {
        path: '/custom-PreChargeCardList',
        component: () => import('@/page/custom/PreChargeCardList'),
        meta: {
            title: '会员储值卡管理'
        }
    },
    {
        path: '/custom-StoreManage',
        component: () => import('@/page/custom/StoreManage.vue'),
        meta: {
            title: '店铺管理'
        }
    },

    {
        path: '/custom-Detail',
        component: () => import('@/page/custom/Detail.vue'),
        meta: {
            title: '用户详情'
        }
    },
    {
        path: '/custom-grade',
        component: () => import('@/page/custom/grade.vue'),
        meta: {
            title: '客户等级'
        }
    },
    {
        path: '/custom-MemberCardList',
        component: () => import('@/page/custom/MemberCardList'),
        meta: {
            title: '等级订单'
        }
    },
    {
        path: '/product-Chinacom',
        component: () => import('@/page/product/Chinacom.vue'),
        meta: {
            title: '中台商品'
        }
    },
    {
        path: '/product-Commodity',
        component: () => import('@/page/product/Commodity.vue'),
        meta: {
            title: '商品评价'
        }
    },
    {
        path: '/product-list',
        component: () => import('@/page/product/List.vue'),
        meta: {
            title: '商品管理'
        }
    },
    {
        path: '/product-listAdd',
        component: () => import('@/page/product/listAdd.vue'),
        meta: {
            title: '新增商品'
        }
    },
    { //力瀚中台页面
        path: '/product-LiHanAdd',
        component: () => import('@/page/product/LiHanProduct.vue'),
        meta: {
            title: '新增商品'
        }
    },
    {
        path: '/product-listEdit',
        component: () => import('@/page/product/listEdit.vue'),
        meta: {
            title: '编辑商品'
        }
    },
    {
        path: '/product-sort',
        component: () => import('@/page/product/sort.vue'),
        meta: {
            title: '商品分类'
        }
    },
    {
        path: '/product-Template',
        component: () => import('@/page/product/Template.vue'),
        meta: {
            title: '商品模板'
        }
    },
    {
        path: '/product-Commod',
        component: () => import('@/page/product/Commod.vue'),
        meta: {
            title: '商品分组'
        }
    },
    {
        path: '/goodsLabel',
        component: () => import('@/page/product/goodsLabel.vue'),
        meta: {
            title: '商品标签'
        }
    },
    {
        path: '/product-Brand',
        component: () => import('@/page/product/Brand.vue'),
        meta: {
            title: '品牌管理'
        }
    },
    {
        path: '/activity-Discount',
        component: () => import('@/page/activity/Discount.vue'),
        meta: {
            title: '优惠券管理'
        }
    },

    {
        path: '/activity-CouponCollection',
        component: () => import('@/page/activity/CouponCollection.vue'),
        meta: {
            title: '优惠券领取'
        }
    },
    {
        path: '/activity-GiveDiscount',
        component: () => import('@/page/activity/GiveDiscount.vue'),
        meta: {
            title: '满送券详情'
        }
    },
    {
        path: '/activity-GiveDiscountAdd',
        component: () => import('@/page/activity/GiveDiscountAdd.vue'),
        meta: {
            title: '满送券新增'
        }
    },
    {
        path: '/activity-Withsend',
        component: () => import('@/page/activity/Withsend.vue'),
        meta: {
            title: '满送券'
        }
    },
    {
        path: '/activity-PriceIncrease',
        component: () => import('@/page/activity/PriceIncrease.vue'),
        meta: {
            title: '加价设置'
        }
    },
    {
        path: '/activity-Storedvalue',
        component: () => import('@/page/activity/Storedvalue.vue'),
        meta: {
            title: '储值活动'
        }
    },
    {
        path: '/activity-Suitfor',
        component: () => import('@/page/activity/Suitfor.vue'),
        meta: {
            title: '套装购'
        }
    },
    {
        path: '/activity-Suitforadd',
        component: () => import('@/page/activity/Suitforadd.vue'),
        meta: {
            title: '套装购新增'
        }
    },
    {
        path: '/activity-SuitforEdit',
        component: () => import('@/page/activity/SuitforEdit.vue'),
        meta: {
            title: '套装购编辑'
        }
    },
    {
        path: '/activity-SuitGoods',
        component: () => import('@/page/activity/SuitGoods.vue'),
        meta: {
            title: '套装商品'
        }
    },
    {
        path: '/activity-SeckillSessionEdit',
        component: () => import('@/page/activity/SeckillSessionEdit.vue'),
        meta: {
            title: '秒杀专场编辑'
        }
    },
    {
        path: '/activity-SeckillSessionList',
        component: () => import('@/page/activity/SeckillSessionList.vue'),
        meta: {
            title: '秒杀专场'
        }
    },
    {
        path: '/activity-SeckillSessionAdd',
        component: () => import('@/page/activity/SeckillSessionAdd.vue'),
        meta: {
            title: '秒杀专场添加'
        }
    },
    {
        path: '/activity-SeckillSessionGoodsList',
        component: () => import('@/page/activity/SeckillSessionGoodsList.vue'),
        meta: {
            title: '秒杀商品'
        }
    },
    /********************************/
    {
        path: '/activity-SignActivityEdit',
        component: () => import('@/page/activity/SignActivityEdit.vue'),
        meta: {
            title: '签到活动编辑'
        }
    },
    {
        path: '/activity-SignActivityList',
        component: () => import('@/page/activity/SignActivityList.vue'),
        meta: {
            title: '签到活动'
        }
    },
    {
        path: '/activity-SignActivityAdd',
        component: () => import('@/page/activity/SignActivityAdd.vue'),
        meta: {
            title: '签到活动添加'
        }
    },
    {
        path: '/activity-SignActivityGoodsList',
        component: () => import('@/page/activity/SignActivityGoodsList.vue'),
        meta: {
            title: '兑换奖品'
        }
    },
    /********************************/

    {
        path: '/activity-Manypeople',
        component: () => import('@/page/activity/Manypeople.vue'),
        meta: {
            title: '多人拼团'
        }
    },
    {
        path: '/activity-CollageGoods',
        component: () => import('@/page/activity/CollageGoods.vue'),
        meta: {
            title: '拼团商品'
        }
    },
    {
        name: 'activity-PlaceEdit',
        path: '/activity-PlaceEdit',
        component: () => import('@/page/activity/PlaceEdit.vue'),
        meta: {
            title: '专场详情'
        }
    },
    {
        path: '/activity-placeAdd',
        component: () => import('@/page/activity/PlaceAdd.vue'),
        meta: {
            title: '添加专场'
        }
    },
    {
        path: '/activity-Session',
        component: () => import('@/page/activity/Session.vue'),
        meta: {
            title: '专场管理'
        }
    },
    {
        name: 'activity-SpecialGoods',
        path: '/activity-SpecialGoods',
        component: () => import('@/page/activity/SpecialGoods.vue'),
        meta: {
            title: '专场商品'
        }
    },
        {
            name: 'activity-SpecialSuit',
            path: '/activity-SpecialSuit',
            component: () => import('@/page/activity/SpecialSuit.vue'),
            meta: {
                title: '专场商品'
            }
        },
    {
        path: '/activity-Advert',
        component: () => import('@/page/activity/Advert.vue'),
        meta: {
            title: '弹窗广告'
        }
    },
    {
        path: '/activity-IntegralConfig',
        component: () => import('@/page/activity/IntegralConfig.vue'),
        meta: {
            title: '积分活动列表'
        }
    },
    {
        path: '/activity-IntegralConfigAdd',
        component: () => import('@/page/activity/IntegralConfigAdd.vue'),
        meta: {
            title: '积分活动新增'
        }
    },
    {
        path: '/activity-IntegralConfigEdit',
        component: () => import('@/page/activity/IntegralConfigEdit'),
        meta: {
            title: '积分活动编辑'
        }
    },
    {
        path: '/capital-Banklist',
        component: () => import('@/page/capital/Banklist.vue'),
        meta: {
            title: '银行卡列表'
        }
    },
    {
        path: '/capital-billDetail',
        component: () => import('@/page/capital/billDetail.vue'),
        meta: {
            title: '账单详情'
        }
    },
    {
        path: '/capital-Transaction',
        component: () => import('@/page/capital/Transaction.vue'),
        meta: {
            title: '交易明细'
        }
    },
    {
        path: '/capital-MpWithdraw',
        component: () => import('@/page/capital/MpWithdraw'),
        meta: {
            title: '微信支付提现'
        }
    },
    {
        path: '/partner-account',
        component: () => import('@/page/capital/PartnerAccount.vue'),
        meta: {
            title: '我的账户'
        }
    },
    {
        path: '/order-orderList',
        component: () => import('@/page/order/orderList.vue'),
        meta: {
            title: '订单管理',
        }
    },
    {
        path: '/order-orderList-distributor',
        component: () => import('@/page/order/orderListOfDistributor.vue'),
        meta: {
            title: '订单管理(分销商)',
        }
    },
    {
        path: '/order-CollageOrder',
        component: () => import('@/page/order/CollageOrder.vue'),
        meta: {
            title: '拼团订单'
        }
    },
    {
        path: '/order-CollageInfo',
        component: () => import('@/page/order/CollageInfo.vue'),
        meta: {
            title: '拼团信息'
        }
    },

    {
        path: '/order-orderDetails',
        component: () => import('@/page/order/orderDetails.vue'),
        meta: {
            title: '订单详情'
        }
    },
    {
        path: '/order-SalesOrders',
        component: () => import('@/page/order/SalesOrders.vue'),
        meta: {
            title: '售后订单'
        }
    },
    {
        path: '/order-MiniSalesOrders',
        component: () => import('@/page/order/MiniSalesOrders.vue'),
        meta: {
            title: '小程序售后'
        }
    },
    {
        path: '/order-Deliver',
        component: () => import('@/page/order/Deliver.vue'),
        meta: {
            title: '订单发货'
        }
    },
    {
        path: '/order-RefundDetail',
        component: () => import('@/page/order/RefundDetail.vue'),
        meta: {
            title: '退款详情'
        }
    },
    {
        path: '/order-RepairMiniDetail',
        component: () => import('@/page/order/RefundDetail.vue'),
        meta: {
            title: '维修详情'
        }
    },
    {

        path: '/order-logistics',
        component: () => import('@/page/order/logistics.vue'),
        meta: {
            title: '物流详情'
        }
    },


    {
        path: '/set-Users',
        component: () => import('@/page/set/Users.vue'),
        meta: {
            title: '用户管理'
        }
    },

    {
        path: '/community-Article',
        component: () => import('@/page/community/Article.vue'),
        meta: {
            title: '资源管理'
        }
    },
    {
        path: '/community-Article-Of-Distributor',
        component: () => import('@/page/community/ArticleOfDistributor.vue'),
        meta: {
            title: '推文管理'
        }
    },
    {
        path: '/community-Comment',
        component: () => import('@/page/community/Comment.vue'),
        meta: {
            title: '社区评论'
        }
    },

    {
        name: 'ArticleEdit',
        path: '/community-ArticleEdit',
        component: () => import('@/page/community/ArticleEdit.vue'),
        meta: {
            title: '资源编辑'
        }
    },
    {
        name: 'ArticleAdd',
        path: '/community-ArticleAdd',
        component: () => import('@/page/community/ArticleAdd.vue'),
        meta: {
            title: '资源新增'
        }
    },
    {
        path: '/community-Topics',
        component: () => import('@/page/community/Topics.vue'),
        meta: {
            title: '话题管理'
        }
    },
    {
        path: '/community-Lettres',
        component: () => import('@/page/community/Lettres.vue'),
        meta: {
            title: '文章管理'
        }
    },
    {
        path: '/community-LettresAdd',
        component: () => import('@/page/community/LettresAdd.vue'),
        meta: {
            title: '文章新增'
        }
    },
    {
        path: '/community-LettresEdit',
        component: () => import('@/page/community/LettresEdit.vue'),
        meta: {
            title: '文章编辑'
        }
    },
    {
        path: '/community-Column',
        component: () => import('@/page/community/Column.vue'),
        meta: {
            title: '文章栏目'
        }
    },
    {
        path: '/community-Banners',
        component: () => import('@/page/community/Banners.vue'),
        meta: {
            title: 'Banner管理'
        }
    },
    {
        path: '/capital-WithdrawalRecord',
        component: () => import('@/page/capital/WithdrawalRecord.vue'),
        meta: {
            title: '提现审核'
        }
    },
    {
        path: '/capital-IntegralRecord',
        component: () => import('@/page/capital/IntegralRecord.vue'),
        meta: {
            title: '积分记录'
        }
    },
    {
        path: '/lineage-Journal',
        component: () => import('@/page/lineage/Journal.vue'),
        meta: {
            title: '后台日志'
        }
    },
    {
        path: '/lineage-Download',
        component: () => import('@/page/lineage/Download.vue'),
        meta: {
            title: '下载'
        }
    },
    {
        path: '/lineage-Logmanagement',
        component: () => import('@/page/lineage/Logmanagement.vue'),
        meta: {
            title: '日志管理'
        }
    },
    {
        path: '/lineage-Feedback',
        component: () => import('@/page/lineage/Feedback.vue'),
        meta: {
            title: '建议反馈'
        }
    },
    {
        path: '/lineage-Classification',
        component: () => import('@/page/lineage/Classification.vue'),
        meta: {
            title: '建议分类'
        }
    },
    {
        path: '/system-jichu',
        component: () => import('@/page/system/jichu.vue'),
        meta: {
            title: '基础设置'
        }
    },
    {
        path: '/sale-SensitiveWords',
        component: () => import('@/page/sale/SensitiveWords.vue'),
        meta: {
            title: '敏感词管理'
        }
    },
    {
        path: '/sale-BusinessWords',
        component: () => import('@/page/sale/BusinessWords.vue'),
        meta: {
            title: '业务方敏感词'
        }
    },
    {
        path: '/sale-Business',
        component: () => import('@/page/sale/Business.vue'),
        meta: {
            title: '敏感词业务'
        }
    },
    {
        path: '/news-Push',
        component: () => import('@/page/news/Push.vue'),
        meta: {
            title: '发送消息'
        }
    },
    // {
    //     path: '/news-Vapplet',
    //     component: () => import('@/page/news/Vapplet.vue'),
    //     meta: {
    //         title: '微信小程序消息'
    //     }
    // },
    {
        path: '/news-Gw',
        component: () => import('@/page/news/Gw.vue'),
        meta: {
            title: '官网消息'
        }
    },
    {
        path: '/news-Template',
        component: () => import('@/page/news/Template.vue'),
        meta: {
            title: '消息模板'
        }
    },
    {
        path: '/enterprise',
        component: () => import('@/page/enterprise/enterprise.vue'),
        meta: {
            title: '企业列表'
        }
    },
    ]
},
{
    path: '/login',
    component: () => import( /* webpackChunkName: "login" */ '../page/Login.vue'),
    meta: {
        title: '登录'
    }
},
{
    path: '/404',
    component: () => import( /* webpackChunkName: "login" */ '../page/404.vue'),
    meta: {
        title: '404'
    }
},
{
    path: '/403',
    component: () => import( /* webpackChunkName: "login" */ '../page/403.vue'),
    meta: {
        title: '403'
    }
},
{
    path: '*',
    redirect: '/404'
},
]

export default new Router({
    routes: constantRouterMap
});