
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import citys from './module/citys'
import Vue from 'vue'
import Vuex from 'vuex'
import adminRouter from "./module/adminRouter.js";

Vue.use(Vuex)
const state = {
    storeInfo: {},
    queryParams: {
        page: 1,
        pagesize: 10
    },
}

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        citys,adminRouter
    }
})
