<template>
    <div id="app">
        <router-view></router-view>

    </div>
</template>
<script>
import { request } from '@/common/request';
export default {
    provide() {
        return {
            // reload: this.reload
        }
    },
    data() {
        return {
            // isRouterAlive: true,
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/system/set', {}).then(ret => {
                if (ret.code == 1) {
                    this.$store.dispatch('saveStoreInfo', ret.data);
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
    },
}
</script>
<style>
@import './assets/css/main.css';
@import './assets/css/color-dark.css'; /*深色主题*/
</style>